<div
    class="logo"
    [routerLink]="link"
    fxLayout="row"
    fxLayoutGap="25px"
    fxLayoutAlign="start center"
>
    <img
        [alt]="'Main Logo' | transloco"
        class="main-logo"
        [src]="organizationLogoUrl$ | async"
        onerror="src='/assets/images/enliveo-logo.svg'"
        height="32"
    />
</div>
